import { $, addAction, INIT } from '@situation/setdesign.util';

const selector = '.instagram-slider';
const owlSettings = {
    margin: 0,
    items: 1,
    dots: false,
    nav: false,
    navText: [
        '<svg><use xlink:href="#icon-left"></use></svg>',
        '<svg><use xlink:href="#icon-right"></use></svg>',
    ],
    stagePadding: 50,
    responsive: {
        576: { items: 2, stagePadding: 0, nav: true },
        768: { items: 3, stagePadding: 0, nav: true },
        992: { items: 5, stagePadding: 0, nav: true },
    },
};

function initializeSlider(index, el) {
    const $container = $(el);
    const $carousel = $(el).find('#sbi_images');
    let attempts = 0;
    const init = () => ($carousel.find('.sbi_item').length
        ? $carousel.addClass('owl-carousel').owlCarousel(owlSettings)
              && $container.addClass('loaded')
        : false);
    if (!init()) {
        const interval = setInterval(() => {
            attempts += 1;

            if (init() || attempts >= 180) {
                clearInterval(interval);
            }
        }, 500);
    }
}

addAction(INIT, () => $(selector).each(initializeSlider));
