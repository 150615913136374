import {
    $, addAction, INIT, LAYOUT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const $container = $('.nav-menu__list');
    const $logo = $('.header-nav__brand');
    const $cta = $('.header-nav__cta');
    // eslint-disable-next-line max-len
    const getAvailableWidth = () => SetDesign.viewport.width() - ($logo.offset().left + $logo.outerWidth() + $cta.outerWidth());
    addAction(LAYOUT, (hasChanged) => {
        if (hasChanged) {
            $container.css('--font-size-nav-items', '');

            if ($container.css('display') === 'flex') {
                const availableWidth = getAvailableWidth();
                if ($container.outerWidth() > availableWidth) {
                    let minimumFontSize = 10;
                    let maxFontSize = parseInt($container.css('font-size'), 10);
                    let fitFontSize = minimumFontSize;

                    while (minimumFontSize <= maxFontSize) {
                        const fontSize = Math.floor((minimumFontSize + maxFontSize) / 2);
                        $container.css('--font-size-nav-items', `${fontSize}px`);

                        if ($container.outerWidth() <= availableWidth) {
                            fitFontSize = fontSize;
                            minimumFontSize = fontSize + 1;
                        } else {
                            maxFontSize = fontSize - 1;
                        }
                    }

                    $container.css('--font-size-nav-items', `${fitFontSize}px`);
                }
            }
        }
    });
});
