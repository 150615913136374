import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    $('.search-widget')
        .parent()
        .each((index, el) => {
            const $container = $(el);
            const $trigger = $container.find('.search-widget-trigger');
            const $widget = $container.find('.search-widget');
            const $input = $widget.find("input[type='text']");
            const $mobileParent = $container.parents('.nav-menu__utility');
            $input.attr('placeholder', 'Search');
            $trigger.on('click', (e) => {
                e.preventDefault();
                $widget.removeClass('search-widget--hide');
                $input.focus();
                $trigger.addClass('search-widget-trigger--hide');

                if ($mobileParent.length) {
                    $mobileParent.addClass('nav-menu__utility--show-search');
                    const $overlay = $mobileParent.parents('.nav-menu__overlay');
                    setTimeout(
                        () => $overlay.animate({ scrollTop: $overlay[0].scrollHeight }, 500),
                        100,
                    );
                }
            });
        });
});
