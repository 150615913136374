import {
    _, $, addAction, READY,
} from '@situation/setdesign.util';

const updateTargets = _.debounce(() => {
    $('[href]').each((i, el) => {
        const $el = $(el);
        $el.attr('target', el.host !== window.location.host ? '_blank' : '');
    });
}, 100);
addAction(READY, () => {
    updateTargets();
    $(document).on('afterAjaxSuccess.tribeEvents', updateTargets);
});
