import {
    $, addAction, doAction, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    function styleSecondaryLink() {
        $('.button--secondary')
            .filter((index, el) => !$(el).find('.icon').length)
            .append(
                $(
                    '<span class="icon icon-caret"><svg><use class="icon-use" xlink:href="#icon-caret"></use></svg></span>',
                ),
            );
    }

    $('.nav-menu .nav-menu__item--has-sub .nav-menu__item--has-sub > a').on('click', (e) => {
        const href = e.currentTarget.getAttribute('href');
        if (e.currentTarget.href && href !== '#') {
            window.location.href = e.currentTarget.href;
        }
    });

    styleSecondaryLink();
    $(document).on('afterSetup.tribeEvents', () => {
        doAction('refreshLazyLoad', $('.tribe-events'));
        styleSecondaryLink();
    });

    $('.gt_switcher .gt_option').unbind('mousewheel');
});
