import {
    $, addAction, doAction, INIT,
} from '@situation/setdesign.util';

const selector = '.image-carousel__inner';
const owlSettings = {
    margin: 10,
    items: 1,
    dots: false,
    nav: false,
    loop: true,
    center: true,
    responsiveClass: true,
    navText: [
        '<svg><use xlink:href="#icon-left"></use></svg>',
        '<svg><use xlink:href="#icon-right"></use></svg>',
    ],
    stagePadding: 50,
    responsive: {
        576: {
            items: 3, slideBy: 1, stagePadding: 70, nav: true, margin: -90,
        },
        650: {
            items: 3, slideBy: 1, stagePadding: 70, nav: true, margin: -120,
        },
        768: {
            items: 3, slideBy: 1, stagePadding: 70, nav: true, margin: -150,
        },
        992: {
            items: 3, slideBy: 1, stagePadding: 70, nav: true, margin: -200,
        },
        1200: {
            items: 3, slideBy: 1, stagePadding: 70, nav: true, margin: -250,
        },
        1400: {
            items: 3, slideBy: 1, stagePadding: 70, nav: true, margin: -300,
        },
        1800: {
            items: 3, slideBy: 1, stagePadding: 70, nav: true, margin: -400,
        },
        2200: {
            items: 3, slideBy: 1, stagePadding: 70, nav: true, margin: -490,
        },
    },
};

function initializeSlider(index, el) {
    const $container = $(el);
    $container
        .addClass('owl-carousel')
        .owlCarousel(owlSettings)
        .on('changed.owl.carousel', () => {
            doAction('refreshLazyLoad', $container);
        });
}

addAction(INIT, () => $(selector).each(initializeSlider), 5);
