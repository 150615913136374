import {
    $, addAction, doAction, INIT,
} from '@situation/setdesign.util';
import dayjs from 'dayjs';

const selector = '.event-slider';
const owlSettings = {
    margin: 0,
    items: 1,
    dots: false,
    nav: false,
    navText: [
        '<svg><use xlink:href="#icon-left"></use></svg>',
        '<svg><use xlink:href="#icon-right"></use></svg>',
    ],
    stagePadding: 50,
    responsive: {
        576: { items: 2, stagePadding: 0, nav: true },
        768: { items: 3, stagePadding: 0, nav: true },
    },
};
const owlFeatured = { 1200: { autoWidth: true } };
const owlStandard = { 992: { items: 4, stagePadding: 0, nav: false } };
const now = dayjs();
const parentIDs = [];

function removeDuplicates(i, card) {
    const $card = $(card);
    const parentID = $card.data('parentId');
    if (parentIDs.indexOf(parentID) !== -1) {
        $card.remove();
    } else {
        parentIDs.push(parentID);
    }
}

function initializeSlider(index, el) {
    const $container = $(el);
    const atts = { stickyFeatured: false, ...$container.data('eventSlider') };
    // Remove expired event cards in case of page cache overlap
    $container
        .find('.event-card')
        .filter((i, card) => dayjs($(card).data('endDate')).isBefore(now))
        .remove();

    // Remove duplicate recurring
    $container.find('.event-card').each(removeDuplicates);
    let $featured = $container.find('.event-card--featured');
    if ($featured.length && atts.stickyFeatured) {
        $featured.first().prependTo($container);
    }

    $container.find('.event-card').slice(4).remove();
    $container.find('.event-card').last().removeClass('event-card--featured');
    $featured = $container.find('.event-card--featured');

    if ($featured.length) {
        $container.find('.event-card').slice(3).remove();
        $featured.slice(1).removeClass('event-card--featured');
    }

    owlSettings.responsive = $container.find('.event-card--featured').length
        ? { ...owlSettings.responsive, ...owlFeatured }
        : { ...owlSettings.responsive, ...owlStandard };

    $container
        .addClass('owl-carousel')
        .owlCarousel(owlSettings)
        .on('changed.owl.carousel', () => {
            doAction('refreshLazyLoad', $container);
        });
}

addAction(INIT, () => $(selector).each(initializeSlider), 5);
